import React, {useState} from "react";
import athenaLogo from "../assets/images/athena-logo.png";
import OnboardingView1 from "../components/onboarding/onboardingView1";
import OnboardingView2 from "../components/onboarding/onboardingView2";
import OnboardingView3 from "../components/onboarding/onboardingView3";
import OnboardingView4 from "../components/onboarding/onboardingView4";
import OnboardingView5 from "../components/onboarding/onboardingView5";
import OnboardingView6 from "../components/onboarding/onboardingView6";
import chimplord_1 from "../assets/images/chimplord_1.png";
import chimplord_2 from "../assets/images/chimplord_2.png";
import chimplord_3 from "../assets/images/chimplord_3.png";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {register, signup} from "../utils/auth";
export default function Onboarding() {
  const [viewState, setViewState] = useState(0);
  const [prevViewState, setPrevViewState] = useState(0);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [school, setSchool] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("");
  const [grade, setGrade] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedCurriculum, setSelectedCurriculum] = useState("");
  const [selectedMathLevel, setSelectedMathLevel] = useState("");
  const [selectedHourOption, setSelectedHourOption] = useState("");
  const [selectedChapters, setSelectedChapters] = useState([]);
  const onSubmit = async () => {
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    const {data, error} = await signup(name, email, school, gender, grade, password, selectedCurriculum, selectedMathLevel, selectedHourOption, selectedChapters)
    console.log(data, error, "data, error")
    if (error) {
      toast.error(error);
    } else {
      navigate("/login");
    }
  }
  return(
      <div className={`dark:bg-darkBackground min-h-screen w-full flex`}>
        <div className={`w-1/2 flex items-center justify-center`}>
          <div className={`absolute top-11 left-12`}>
            <button className={`dark:text-white text-4xl font-Nasa text-bold`} onClick={()=>{
              navigate("/");
            }}>EPICENTER</button>
          </div>
          <ToastContainer />
          {viewState === 0 && (<OnboardingView1 setViewState={setViewState} name={name} setName={setName} email={email} setEmail={setEmail} school={school} setSchool={setSchool} password={password} setPassword={setPassword} confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword} gender={gender} setGender={setGender} grade={grade} setGrade={setGrade}/>)}
          {viewState === 1 && (<OnboardingView2 prevViewState={prevViewState} setPrevViewState={setPrevViewState} setViewState={setViewState} selectedCurriculum={selectedCurriculum} setSelectedCurriculum={setSelectedCurriculum}/>)}
          {viewState === 2 && (<OnboardingView3 prevViewState={prevViewState} setPrevViewState={setPrevViewState} setViewState={setViewState} selectedMathLevel={selectedMathLevel} setSelectedMathLevel={setSelectedMathLevel}/>)}
          {viewState === 3 && (<OnboardingView4 prevViewState={prevViewState} setPrevViewState={setPrevViewState} setViewState={setViewState} selectedHourOption={selectedHourOption} setSelectedHourOption={setSelectedHourOption}/>)}
          {viewState === 4 && (<OnboardingView5 setViewState={setViewState} selectedChapters={selectedChapters} setSelectedChapters={setSelectedChapters} onSubmit={onSubmit}/>)}
          {viewState === 5 && (<OnboardingView6 setViewState={setViewState}/>)}
        </div>
        <div className={`w-1/2 flex items-center justify-center`}>
          <img src={(viewState % 3)===0 ? chimplord_1 : (viewState % 3 === 1 ? chimplord_2 : chimplord_3)} alt="athena logo" className={`w-10/12`}/>
        </div>
      </div>
  )
}

