import React, {useEffect, useRef, useState} from 'react';
import caretButton from '../assets/icons/caretButtonBlack.svg';
const Dropdown = ({data, placeholder, bgColor="#1c2226", option, setOption}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <button className="relative text-left w-full" ref={dropdownRef}>
      <button
        type="button"
        style={{
          backgroundColor: bgColor,
        }}
        className={`flex justify-center items-center w-full rounded-xl p-4 text-base font-Sa text-black dark:text-white focus:outline-none placeholder:text-white`}
        onClick={toggleDropdown}
      >
        {option||placeholder}
        <img src={caretButton} className={`ml-2.5 -rotate-90 h-5 w-5 dark:invert`} alt="dropdown" />
      </button>

      {isOpen && (
        <div className="origin-top-right dark:bg-darkBackground dark:text-white absolute left-0 mt-2 w-48 rounded-lg shadow-lg shadow-[#2e3c41] bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div role="none">
            {
              data.map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      setOption(item);
                      setIsOpen(false);
                    }}
                    key={index}
                    className={`font-Sa z-10 ${index===0 ? 'rounded-t-md' : (index===data?.length-1 ? 'rounded-b-md' : '')} hover:bg-white hover:text-black block px-4 py-2 text-sm`}
                    role="menuitem"
                    tabIndex="-1"
                    id={`menu-item-${index}`}
                  >
                    {item}
                  </div>
                );
              })
            }
          </div>
        </div>
      )}
    </button>
  );
};

export default Dropdown;
