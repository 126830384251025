import React, {useEffect, useRef, useState} from "react";
import Dropdown from "../dropdown";
import StatusButton from "./StatusButton";
import CollapsibleCarousel from "./collapsibleCarousel";

export default function Answers({response}) {
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [correct, setCorrect] = useState(0);
  const [incorrect, setIncorrect] = useState(0);
  const [skipped, setSkipped] = useState(0);
  useEffect(() => {
      response?.forEach(({ verdict }) => {
        if (verdict === 1) {
          setCorrect((value) => value + 1);
        } else if (verdict === 0) {
          setIncorrect((value) => value + 1);
        } else {
          setSkipped((value) => value + 1);
        }
      });
  }, [ response]);

  //   const duplicateResponse = (response) => {
  //   return response.concat(response).concat(response);
  // };
  //
  // const duplicatedResponse = duplicateResponse(response);
  return(
      <div className={`w-full dark:bg-darkBackground`}>
        {/*<p className={`text-base dark:text-white mt-4 font-Sa mb-14`}>Your performance was <span className={`font-SaB`}>40%</span> better than your last assessment in the same topic.</p>*/}
        <p className={`text-3xl font-SaB mt-14 dark:text-white`}>Answers</p>
        <p className={`text-base font-Sa mb-12 dark:text-white`}>Review your answers. Learn from these explanations of correct and
          incorrect response options. </p>
        <div className={`flex flex-col dark:bg-darkBackground`}>
          <div className={`flex justify-between items-center mb-3`}>
            <div className={`w-2/12 z-50`}>
              <Dropdown data={["Correct"]} placeholder={"Select option"} bgColor={"#2E3C41"}/>
            </div>
            <div className="space-x-4">
              <StatusButton status="Correct" count={correct}/>
              <StatusButton status="Incorrect" count={incorrect}/>
              <StatusButton status="Skipped" count={skipped}/>
            </div>
          </div>
          <div className={`w-full flex h-fit flex-col gap-y-4 dark:bg-darkBackground pb-[20rem]`}>
            {
              response?.map(({question, answer, correct_answer, explanation, verdict}, index) => (
                  <CollapsibleCarousel key={index} question={question} answer={explanation} status={verdict} userAnswer={answer} correctAnswerOption={correct_answer}/>
              ))
            }
          </div>

        </div>
      </div>
  )
}