import React, {useRef, useState} from "react";
import exploreButton from "../../assets/icons/chapterBoxButton.svg";
import {useNavigate} from "react-router-dom";
import ProgressRing from "../ProgressRing";
import chapterBoxButton from "../../assets/icons/chapterBoxButton.svg";
import roadmapLockedIndicatior from "../../assets/icons/roadmapLockedIndicatior.svg"

function getColorByPercentage(color, percent){
  let R1 = parseInt(color.substring(1, 3), 16);
  let G1 = parseInt(color.substring(3, 5), 16);
  let B1 = parseInt(color.substring(5, 7), 16);
  let R2;
  let G2;
  let B2;
  if(percent>0){
    R2 = Math.round(R1 + (255 - R1) * ((100 - percent) / 100));
    G2 = Math.round(G1 + (255 - G1) * ((100 - percent) / 100));
    B2 = Math.round(B1 + (255 - B1) * ((100 - percent) / 100));
  }
  // console.log(R2, G2, B2, "R, G, B")

  R2 = (R2 < 255) ? R2 : 255;
  G2 = (G2 < 255) ? G2 : 255;
  B2 = (B2 < 255) ? B2 : 255;

  const RR = R2.toString(16).padStart(2, '0');
  const GG = G2.toString(16).padStart(2, '0');
  const BB = B2.toString(16).padStart(2, '0');
  return `#${RR}${GG}${BB}`;
}
export default function ExploreChapterItems({key, bgColor, buttonBgColor, mainTopic, subTopic, topic_id, nav_link, useLocationState, ringColor='#0f9d76'}) {
  const exploreButtonRef = useRef(null);
  const navigate = useNavigate();
  const handleHoverEnter = () => {
    // exploreButtonRef.current.classList.add("rotate-90");
    exploreButtonRef.current.classList.add("opacity-100");
    exploreButtonRef.current.classList.add("dark:opacity-100");
    exploreButtonRef.current.classList.add("scale-110");
    // exploreButtonRef.current.classList.remove("-rotate-0");
    exploreButtonRef.current.classList.remove("opacity-30");
    exploreButtonRef.current.classList.remove("dark:opacity-60");
    exploreButtonRef.current.classList.remove("scale-100");
  }
  const handleHoverLeave = () => {
    // exploreButtonRef.current.classList.add("-rotate-0");
    exploreButtonRef.current.classList.add("opacity-30");
    exploreButtonRef.current.classList.add("dark:opacity-60");
    exploreButtonRef.current.classList.add("scale-100");
    // exploreButtonRef.current.classList.remove("rotate-90");
    exploreButtonRef.current.classList.remove("opacity-100");
    exploreButtonRef.current.classList.remove("dark:opacity-100");
    exploreButtonRef.current.classList.remove("scale-110");
  }
  const [percentageCompleted, setPercentageCompleted] = useState(100);
  const handleClick = () => {
    navigate(nav_link, {state: useLocationState})
    window.scrollTo({ top: 0, behavior: "instant" });
    console.log("clicked");
  }
  return (
      <div
          onMouseEnter={handleHoverEnter}
          onMouseLeave={handleHoverLeave}
          key={key}
          className={`flex flex-col relative cursor-pointer w-52 h-52 py-6 px-6 min-h-fit bg-cover bg-no-repeat hover:scale-110 hover:translate-x-[5%] hover:translate-y-[5%] transition-transform rounded-3xl rounded-br-[3rem]`}
          onClick={() => {
            handleClick();
          }}
          style={{
            backgroundColor: bgColor,
          }}
      >
        <div className={`flex flex-col`}>
          <p className={`font-SaM text-[#33333399] dark:text-white dark:text-opacity-60 text-sm leading-5 mb-1`}>{mainTopic}</p>
          <p className={`font-SaM text-[#4A4A4A] dark:text-white text-lg`}>{subTopic}</p>
        </div>
        <button
            className={`absolute -bottom-5 -right-4 font-Sa w-24 h-24 text-lg rounded-full`}
            style={{
              backgroundColor: buttonBgColor
            }}
        >
        </button>

        {/* <button ref={exploreButtonRef} className={`absolute opacity-30 bg-greenAccent dark:bg-darkgreenAccent dark:opacity-60 p-3 rounded-full -bottom-1 right-0 w-[4.2rem] text-lg transition-all ease-in-out`}>
          <img src={exploreButton} className={`w-full`} alt={'explore'}/>
        </button> */}
        <button ref={exploreButtonRef} style={{ '--progress-percentage': `${50}%`, backgroundColor: getColorByPercentage("#FFFFFF", 50) }} className={`progress-circle flex justify-center items-center absolute z-10 -bottom-0 right-0 w-16 h-16 dark:opacity-60 rounded-full text-lg transition-transform`}>
            <ProgressRing strokeColor={ringColor } radius={39} stroke={4.5} progress={percentageCompleted}/>
            <p className={`font-Nasa text-xl`} style={{
              color: percentageCompleted === 100 ? "#1c2226" : "#1c2226"
            }}>{percentageCompleted}</p>
        </button>
      </div>
  )
}