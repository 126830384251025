import React, {useState} from "react";

export default function WeeklyStats({mastered_topics, danger_topics, scoring_topics, quesions_practiced, avg_accuracy, correct_count, incorrect_count}) {
    const hourData = [
        { day: "Mon", hours: 2, color: "#FFFFFF" },
        { day: "Tue", hours: 4, color: "#FFFFFF" },
        { day: "Wed", hours: 3, color: "#FFFFFF" },
        { day: "Thu", hours: 1, color: "#FFFFFF" },
        { day: "Fri", hours: 5, color: "#FFFFFF" },
        { day: "Sat", hours: 2, color: "#FFFFFF" },
    ];
    // const [data, setData] = useState([]);

    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    return (
        <div className={`w-full flex mb-7 gap-x-4`}>
            <div className={`w-8/12 relative grid grid-rows-2 grid-cols-3 gap-x-0 gap-y-5`}>
                <div
                    className={`min-w-fit w-52 shadow-md bg-[#FFFFFF] dark:bg-white p-3 rounded-3xl inline-flex py-4 flex-col justify-center items-center`}>
                    <p className={`font-SaB text-lg text-center w-fit text-[#2e3c41] mb-2`}>Mastered Chapters</p>
                    <p className={`font-Nasa text-5xl text-center w-fit text-darkgreenAccent`}>{mastered_topics?.length}</p>
                </div>
                <div
                    className={`min-w-fit w-52 shadow-md bg-[#FFFFFF] dark:bg-[#2e3c41] p-3 rounded-3xl inline-flex py-4 flex-col justify-center items-center`}>
                    <p className={`font-SaB text-lg text-center w-fit text-white mb-2`}>Questions Solved</p>
                    <p className={`font-Nasa text-5xl text-center w-fit text-darkgreenAccent`}>{quesions_practiced}</p>
                </div>
                <div
                    className={`inline-flex justify-center bg-white dark:bg-darkgreenAccent rounded-3xl pt-4 pl-5 pb-8 min-w-fit h-48 items-center shadow-md `}>
                    <div className={`inline-flex flex-col items-center h-fit w-fit`}>
                        <h3 className={`text-xl font-SaB whitespace-nowrap dark:text-[#2e3c41] mr-4`}>Weekly
                            Overview</h3>
                        {/*<h3 className={`text-sm font-Sa whitespace-nowrap dark:text-white`}>Apr 10 - Apr 17</h3>*/}
                    </div>

                    <div className={`w-fit pr-5 flex flex-col items-end relative`}>
                        <div className={`inline-flex h-full items-end ml-0`}>
                            {hourData.map((item, index) => (
                                <div key={index} className={`flex flex-col items-center mx-2`}>
                                    <div className={`text-sm font-SaM mb-2 text-white`}>{item.hours}h</div>
                                    <div
                                        className={`bar w-4 rounded-full mb-2`}
                                        style={{
                                            backgroundColor: item.color,
                                            "--bar-height": `${item.hours * 1.3}rem`,
                                        }}
                                    />
                                    <div className={`text-sm text-white font-SaM mt-2`}>{days[index]}</div>
                                </div>
                            ))}
                        </div>
                        {/*<hr className={`border-t-2 w-11/12 mr-3 border-[#E5E5E5] dark:border-[#B1B1B1] dark:opacity-50 mt-4`}/>*/}
                    </div>
                </div>

                <div
                    className={`flex justify-center bg-white dark:bg-darkgreenAccent rounded-3xl pt-4 pl-5 pb-8 min-w-fit h-48 items-center shadow-md `}>
                    <div className={`inline-flex flex-col items-center h-fit w-fit`}>
                        <h3 className={`text-xl font-SaB whitespace-nowrap dark:text-[#2e3c41] mr-4`}>Weekly
                            Overview</h3>
                        {/*<h3 className={`text-sm font-Sa whitespace-nowrap dark:text-white`}>Apr 10 - Apr 17</h3>*/}
                    </div>

                    <div className={`w-fit pr-5 flex flex-col items-end relative`}>
                        <div className={`inline-flex h-full items-end ml-0`}>
                            {hourData.map((item, index) => (
                                <div key={index} className={`flex flex-col items-center mx-2`}>
                                    <div className={`text-sm font-SaM mb-2 text-white`}>{item.hours}h</div>
                                    <div
                                        className={`bar w-4 rounded-full mb-2`}
                                        style={{
                                            backgroundColor: item.color,
                                            "--bar-height": `${item.hours * 1.3}rem`,
                                        }}
                                    />
                                    <div className={`text-sm text-white font-SaM mt-2`}>{days[index]}</div>
                                </div>
                            ))}
                        </div>
                        {/*<hr className={`border-t-2 w-11/12 mr-3 border-[#E5E5E5] dark:border-[#B1B1B1] dark:opacity-50 mt-4`}/>*/}
                    </div>
                </div>

                <div
                    className={`min-w-fit w-52 shadow-md bg-[#FFFFFF] ml-[15.5rem] dark:bg-[#2e3c41] p-5 rounded-3xl inline-flex py-4 flex-col justify-center items-center`}>
                    <p className={`font-SaB text-lg text-center w-fit text-white mb-2`}>Average Accuracy</p>
                    <p className={`font-Nasa text-5xl text-center w-fit text-darkgreenAccent`}>{avg_accuracy?.toFixed(1)}%</p>
                </div>
                <div
                    className={`min-w-fit w-52 shadow-md bg-[#FFFFFF] ml-[15.5rem] dark:bg-white p-5 rounded-3xl inline-flex py-4 flex-col justify-center items-center`}>
                        <p className={`font-SaB text-lg text-center w-fit text-[#2e3c41] mb-2`}>Incorrect : Correct</p>
                    <p className={`font-Nasa text-5xl text-center w-fit text-darkgreenAccent`}>{`${incorrect_count}:${correct_count}`}</p>
                </div>
            </div>

        </div>
    );
}
