import React from "react";
import SearchBar from "./searchBar";
import profile from "../assets/icons/profile.svg";
import settings from "../assets/icons/settings.svg";
import { useNavigate } from "react-router-dom";

export default function Header({margin="0rem", }) {
    const navigate = useNavigate();

    const handleProfileClick = () => {
        navigate("/profile");
    };
  return(
      <div className={`flex items-center w-full pt-10 px-12`}>
        {/*<p className={`font-SaM text-3xl text-black dark:text-white min-w-60 w-[22%]`}>athena</p>*/}
        {/*<div className={`absolute top-10 left-10`}>*/}
        <p className={`dark:text-white text-4xl font-Nasa text-bold min-w-60 w-[22%]`}>EPICENTER</p>
        {/*</div>*/}
        <div className={`w-6/12 px-4 flex`} style={{
            marginLeft: `${margin}`
        }}>
          <SearchBar placeholder={"Search"}
                     className={"rounded-xl sm:text-sm sm:leading-6 placeholder:text-sm dark:text-white pl-12 pr-5"}/>
        </div>
        <div className={`flex gap-x-8 w-[28%] justify-end`}>
          {/*  Search Button */}
          <button className={`w-10 h-10 rounded-full flex items-center justify-center`}>
            <img src={settings} className={`w-full h-full dark:invert`} alt="settings"/>
          </button>
          {/*  Profile Button */}
          <button className={`w-10 h-10 rounded-full flex items-center justify-center`} onClick={handleProfileClick}>
            <img src={profile} className={`w-full h-full dark:invert`} alt="profile"/>
          </button>
        </div>
      </div>
  )
}