import React, {useEffect, useState} from "react";
import blackEdit from "../../assets/icons/blackEdit.svg";
import CollapsibleCarousel from "./collapsibleCarousel";
import BACKEND_URL from "../../constants";
import useAxios from "../../utils/useAxios";
import startPracticeBannerBgGreen from "../../assets/icons/startPracticeBannerBgGreen.png";
import startPracticeBannerBgWhite from "../../assets/icons/startPracticeBannerBgWhite.png";
import startPracticeBannerBgRed from "../../assets/icons/startPracticeBannerBgRed.png";
import whiteEdit from "../../assets/icons/whiteEdit.svg";
import rocket from "../../assets/icons/rocket.svg";
import whiteSlider from "../../assets/icons/whiteSlider.svg";
import greenSlider from "../../assets/icons/greenSlider.svg";
import redSlider from "../../assets/icons/redSlider.svg";
import {useNavigate} from "react-router-dom";

export default function LearningSchedule({setViewMode}) {
    const [mode, setMode] = useState(0);
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [data, setData] = useState([]);
    const axios = useAxios();
    const [unlocked, setUnlocked] = useState(false);
    const [daysLeft, setDaysLeft] = useState(0);
    const navigate = useNavigate();
    // Function to build query string from an array of IDs
// function buildQueryString(ids) {
//     return `ids=${ids.join(',')}`;
// }

// Example usage
// const ids = [1, 2, 3, 4];
// const baseURL = 'https://example.com/api/resource';
// const queryString = buildQueryString(ids);
// const url = `${baseURL}?${queryString}`;
//
// // Making the GET request using axios
// axios.get(url)
//     .then(response => {
//         console.log(response.data);
//     })
//     .catch(error => {
//         console.error('Error fetching data:', error);
//     });

    // Function to build query string from an array of IDs
// function buildQueryString(ids) {
//     return `ids=${ids.join(',')}`;
// }
//
// // Example usage
// const ids = [1, 2, 3, 4];
// const baseURL = 'https://example.com/api/resource';
// const queryString = buildQueryString(ids);
// const url = `${baseURL}?${queryString}`;
//
// // Making the GET request using axios
// axios.get(url)
//     .then(response => {
//         console.log(response.data);
//     })
//     .catch(error => {
//         console.error('Error fetching data:', error);
//     });
//
    const [scoringTopics, setScoringTopics] = useState([]);
    const [dangerTopics, setDangerTopics] = useState([]);
    const [confidenceLevel, setConfidenceLevel] = useState(30);

    useEffect(() => {
        axios.get(`${BACKEND_URL}/athena_student/learning_plan`).then((response) => {
            console.log(response, "response")
            setData(response?.data?.learning_modules);
            setUnlocked(response?.data?.unlocked);
            setDangerTopics(response?.data?.danger_topics);
            setScoringTopics(response?.data?.scoring_topics);
            setDaysLeft(response?.data?.days_left);
            // setConfidenceLevel(response?.data?.confidence_level);
        });
    }, []);
    return (
        <div className={`w-full`}>
            <div className={`flex flex-col items-center w-full`}>
                {/*<div className={`w-fit rounded-xl bg-[#F9FAFB] dark:bg-[#121212] text-base p-2 flex gap-10 font-SaB relative`}>*/}
                {/*    <div*/}
                {/*        id="slider"*/}
                {/*        className={`absolute bottom-[0.2rem] z-0 left-1.5 transition-transform duration-300 ${*/}
                {/*            mode === 1 ? "translate-x-[119%]" : ""*/}
                {/*        } w-[44%] h-[85%] bg-white dark:bg-[#222222] rounded-lg`}*/}
                {/*        style={{*/}
                {/*            boxShadow: `${!isDarkMode ? `0px 2px 6px -2px rgba(212, 212, 212, 1),*/}
                {/*            0px 0px 0px 1px rgba(234, 237, 240, 1),*/}
                {/*            0px -2px 4px 0px rgba(244, 244, 244, 1) inset` : ''}`,*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    <button*/}
                {/*        className={`${mode === 0 ? "text-[#2A323A] dark:text-white" : "text-[#888888] font-Sa"}  z-10 px-5 py-1 `}*/}
                {/*        onClick={() => {*/}
                {/*            setMode(0);*/}
                {/*        }}>*/}
                {/*        Learning Plan*/}
                {/*    </button>*/}
                {/*    <button*/}
                {/*        className={`${mode === 1 ? "text-[#2A323A] dark:text-white" : "text-[#888888] font-Sa"} z-10 pl-9 pr-10 py-1 `}*/}
                {/*        onClick={() => {*/}
                {/*            setMode(1);*/}
                {/*        }}>*/}
                {/*        Insights*/}
                {/*    </button>*/}
                {/*</div>*/}
                <div className={`flex w-full items-center mt-10 rounded-xl`}>
                    <div className={`flex w-[95%] justify-between bg-[#2E3C41] rounded-3xl py-10`}>
                        <div className={`flex items-center text-2xl w-1/2`}>
                            <p className={`font-SaB text-white ml-10`}>Learning Plan</p>
                            <button className={`w-6 h-6 ml-3`} onClick={()=>{
                                setViewMode(1);
                            }}>
                                <img className={`w-full`} src={whiteEdit} alt={`edit`}/>
                            </button>
                        </div>
                        <div className={`flex flex-col gap-y-6 w-6/12 items-end pr-10`}>
                            <div className={`w-5/6 rounded-2xl bg-darkgreenAccent text-lg p-5 flex items-center justify-between`}>
                                <p className={`text-white font-SaM`}>Confidence Level</p>
                                <p className={`text-black font-Nasa`}>{confidenceLevel} %</p>
                            </div>
                            <div className={`w-5/6 rounded-2xl bg-darkBackground text-lg p-5 flex items-center justify-between`}>
                                <p className={`text-white font-SaM`}>Days Left</p>
                                <p className={`text-darkgreenAccent font-Nasa`}>{daysLeft}</p>
                            </div>
                            <button onClick={()=>{
                                navigate(`/mcq/1/1` , {state: {previousURL: "learningPlan", isMockExam: true}})
                            }} className={`w-5/6 rounded-2xl bg-white text-lg p-5 flex items-center justify-between`}>
                                <p className={`text-black font-SaM`}>Mock Test</p>
                                <img className={`w-5 h-5`} src={rocket}/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`flex flex-col w-full my-8`}>
                    {/*<hr className={`w-[93%] border border-[#E8E8E8] my-8 `}/>*/}
                    <div className={`flex flex-col gap-y-7`}>
                        {
                            data?.map(({topic, id}, index) => {
                                return (
                                    <CollapsibleCarousel unlocked={true} bgImage={startPracticeBannerBgWhite} key={index} topic={topic}
                                                         id={id} heading={topic?.[0]?.chapter?.name} bgColor={"#0F9D76"}
                                                         textColor={'white'} carouselBgColor={"#2E3C41"}
                                                         buttonBgColor={"white"} buttonTextColor={"black"} sliderIcon={whiteSlider}/>
                                )
                            })
                        }
                        <CollapsibleCarousel unlocked={unlocked} topic={scoringTopics} id={0}
                                              heading={"Grade Boosters"} bgColor={'white'}
                                              textColor={'#0F9D76'} bgImage={startPracticeBannerBgGreen}
                                              carouselBgColor={"#2E3C41"} buttonBgColor={"#0F9D76"}
                                              buttonTextColor={"white"} sliderIcon={greenSlider}/>

                        <CollapsibleCarousel unlocked={unlocked} topic={dangerTopics} id={0} heading={"Danger Concepts"}
                                              bgColor={'white'} textColor={'#FF5963'} bgImage={startPracticeBannerBgRed}
                                              carouselBgColor={"#2E3C41"} buttonBgColor={"#FF5963"}
                                              buttonTextColor={"white"} ringColor={"#FF5963"} sliderIcon={redSlider}/>

                    </div>
                </div>
            </div>
        </div>
    );
}
