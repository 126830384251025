import React, {useEffect, useState} from "react";
import Navbar from "../components/Navbar/navbar";
import ScoreGraph from "../components/insights/graph";
import caretButton from "../assets/icons/caretButtonBlack.svg";
import Answers from "../components/insights/answers";
import ToggleButtonMenu from "../components/toggleButtonMenu";
import {useLocation} from "react-router-dom";
import useAxios from "../utils/useAxios";
import BACKEND_URL from "../constants";
import whiteEdit from "../assets/icons/whiteEdit.svg";
import rocket from "../assets/icons/rocket.svg";
import Header from "../components/Header";


export default function Insights(){
  const axios = useAxios();
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const response = useLocation().state?.response;
  const [bgToggle, setBgToggle] = useState(false);
  const [mode, setMode] = useState(0);
  const [data, setData] = useState([]);
  return(
      <div className={`bg-background dark:bg-darkBackground min-h-screen w-full flex select-none`}>
        <div className={`flex flex-col w-full`}>
          <Header margin={"3rem"}/>
           <div className={`flex w-full`}>
        <Navbar/>
          <div
              className={`w-[78%] pl-20  py-6 ${bgToggle ? 'bg-white dark:bg-darkBackground pr-60' : 'bg-background dark:bg-darkBackground pr-20'} relative flex flex-col`}>

              {/*<h1 className={`text-4xl font-SaB mb-7 dark:text-white`}>Your Score: 87</h1>*/}
              {/*<ScoreGraph/>*/}
              <div className={`flex w-full items-center mt-10 rounded-xl`}>
                  <div className={`flex w-full justify-between bg-[#2E3C41] rounded-3xl py-10`}>
                      <div className={`flex items-center text-2xl w-1/2`}>
                          <p className={`font-SaB text-white ml-10`}>Great Job!</p>
                      </div>
                      <div className={`flex flex-col gap-y-6 w-6/12 items-end pr-10`}>
                          <div className={`w-5/6 rounded-2xl bg-darkgreenAccent text-lg p-5 flex justify-between`}>
                              <p className={`text-white font-SaM`}>Accuracy</p>
                              <p className={`text-black font-Nasa`}>80%</p>
                          </div>
                          <div className={`w-5/6 rounded-2xl bg-darkBackground text-lg p-5 flex justify-between`}>
                              <p className={`text-white font-SaM`}>Time Taken</p>
                              <p className={`text-darkgreenAccent font-Nasa`}>24</p>
                          </div>
                          <button className={`w-5/6 rounded-2xl bg-white text-lg p-5 flex justify-between`}>
                              <p className={`text-black font-SaM`}>Placeholder</p>
                              <img className={`w-5 h-5`} src={rocket}/>
                          </button>
                      </div>
                  </div>
              </div>
              {response !== null && response !== undefined && <Answers response={response}/>}
              {/*  <div className={`inline-flex flex-col items-center w-full `}>*/}
              {/*    <div className={`w-fit rounded-xl bg-[#FFFFFF] text-base dark:bg-[#2e3c41] p-2 inline-flex justify-between font-SaB mb-14 relative`}>*/}
              {/*      <div id="slider" className={`absolute bottom-[0.275rem] border-gray-200 border dark:border-[#222222] z-0 left-1 transition-transform shadow-lg duration-300  ${mode === 1 ? ' translate-x-[98%] w-[32.0%]' : `${mode === 2 ? 'translate-x-[175%] w-[35.75%]' : 'w-[27.5%]'}`} h-[85%] bg-white dark:bg-darkBackground rounded-lg`}></div>*/}
              {/*      <button className={`${(mode === 0) ? 'text-[#2A323A] dark:text-white' : 'text-[#888888] font-Sa text-center'} z-10 ml-6 mr-[4.5rem] py-2 `}*/}
              {/*          onClick={() => {*/}
              {/*            setMode(0)*/}
              {/*          }}>Overall*/}
              {/*      </button>*/}
              {/*      <button className={`${(mode === 1) ? 'text-[#2A323A] dark:text-white' : 'text-[#888888] font-Sa'} z-10 mr-7 py-2 `}*/}
              {/*              onClick={() => {*/}
              {/*                setMode(1)*/}
              {/*              }}>Worksheets*/}
              {/*      </button>*/}
              {/*      <button className={`${(mode === 2) ? 'text-[#2A323A] dark:text-white' : 'text-[#888888] font-Sa'} z-10 mx-5 py-2 `}*/}
              {/*              onClick={() => {*/}
              {/*                setMode(2)*/}
              {/*              }}>Assignments*/}
              {/*      </button>*/}
              {/*    </div>*/}
              {/*    /!*<ToggleButtonMenu/>*!/*/}
              {/*    <WeeklyStats mastered_topics={data?.mastered_topics} danger_topics={data?.danger_topics} quesions_practiced={data?.questions_practiced} scoring_topics={data?.scoring_topics}/>*/}
              {/*    <AccuracyAndScore/>*/}
              {/*    <DangerTopics/>*/}
              {/*    <ScoringTopics/>*/}
              {/*    <MasteredTopics/>*/}

              {/*  </div>*/}
          </div>
           </div>
          </div>
      </div>
  )
}
