import React, {useState} from "react";
import FocusedInput from "../components/onboarding/FocusedInput";
import chimplord_1 from "../assets/images/chimplord_1.png";
import {useNavigate} from "react-router-dom";
import {login} from "../utils/auth";
import {toast, ToastContainer} from "react-toastify";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const onSubmit = async () => {
      await login(email, password).then(
        ({data, error}) => {
          if (error) {
            console.log(error, "error");
            toast.error(error.detail, {autoClose: 1000});
          } else {
            navigate("/");
          }
        }
      ).catch((error) => {
          console.log(error, "error");
          toast.error(error.detail);
        }
      )

  }
  return (
    <div className="w-full min-h-screen flex flex-col items-center">
      <ToastContainer />
      <div className={`bg-darkgreenAccent mt-14 mb-8 p-8 w-11/12 rounded-3xl`}>
        <p className={`text-4xl font-Nasa dark:text-white`}>EPICENTER</p>
      </div>
      <div className={`w-11/12 flex justify-between items-center`}>
        <div className={`w-4/12 flex justify-center items-center`}>
          <img src={chimplord_1} alt="chimplord-1" className={`w-full`}/>
        </div>
        <div className="w-5/12 bg-white dark:bg-[#2E3C41] rounded-2xl flex flex-col justify-evenly py-8 items-center">
          <p className="text-2xl font-Nasa dark:text-white mb-8">SIGN IN</p>
          <FocusedInput type={"email"} placeholder="Enter Email" width={'w-10/12 mb-4 px-6 py-4 rounded-lg'} value={email}
              onChange={(event) => setEmail(event.target.value)}/>
          <FocusedInput type={"password"} placeholder="Enter Password" width={'w-10/12 mb-10 px-6 py-4 rounded-lg'} value={password}
              onChange={(event) => setPassword(event.target.value)}/>
          <div className={`flex w-11/12 justify-evenly`}>
            <button onClick={()=>{
              navigate("/onboarding")
            }}
                className="w-[20%] bg-white dark:bg-darkBackground text-black dark:text-white text-base font-SaM py-2 rounded-lg">
              Sign Up
            </button>
            <button onClick={()=>{
              onSubmit();
            }}
                className="w-[20%] bg-white dark:bg-darkgreenAccent text-black dark:text-white text-base font-SaM py-2 rounded-lg">
              Login
            </button>
          </div>

        </div>
      </div>

    </div>
  );
}