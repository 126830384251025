import React, {useEffect, useRef, useState} from "react";
import CarouselImpl from "../CarouselImpl";
import "./style.css";
import learningPlanLockedChapter from "../../assets/icons/learningPlanLockedChapter.svg";
import startPracticeBannerBg from "../../assets/images/startPracticeBannerBg.png";
import ExploreChapterItems from "./exploreChapterItems";
import lockedIndicator from "../../assets/icons/lockedIndicator.svg";
import {useNavigate} from "react-router-dom";
import continuePracticeBg from "../../assets/images/continuePracticeBg.png";
import whiteSlider from "../../assets/icons/whiteSlider.svg";
import greenSlider from "../../assets/icons/greenSlider.svg";
import redSlider from "../../assets/icons/redSlider.svg";

function getColorByPercentage(color, percent){
  let R1 = parseInt(color.substring(1, 3), 16);
  let G1 = parseInt(color.substring(3, 5), 16);
  let B1 = parseInt(color.substring(5, 7), 16);
  let R2;
  let G2;
  let B2;
  if(percent>0){
    R2 = Math.round(R1 + (255 - R1) * ((100 - percent) / 100));
    G2 = Math.round(G1 + (255 - G1) * ((100 - percent) / 100));
    B2 = Math.round(B1 + (255 - B1) * ((100 - percent) / 100));
  }
  // console.log(R2, G2, B2, "R, G, B")

  R2 = (R2 < 255) ? R2 : 255;
  G2 = (G2 < 255) ? G2 : 255;
  B2 = (B2 < 255) ? B2 : 255;

  const RR = R2.toString(16).padStart(2, '0');
  const GG = G2.toString(16).padStart(2, '0');
  const BB = B2.toString(16).padStart(2, '0');
  return `#${RR}${GG}${BB}`;
}


export default function  CollapsibleCarousel({unlocked, bgImage, topic, id, heading, subheading, bgColor, textColor, carouselBgColor, buttonBgColor, buttonTextColor, sliderIcon, ringColor= "#0f9d76"}) {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);
    const levelCardRef = useRef(null);
    const [currentCard, setCurrentCard] = useState(0);
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [levelCardList, setLevelCardList] = useState([]);
    const navigate = useNavigate();
    const color = isDarkMode ? '#33565E' : '#60AFBB';

    // setCircleColor(newColor);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
      let useEffectLevelCardList = [];
      for (let i = 0; i < topic?.length; i++) {
        // const finalCircleFillColor = getColorByPercentage(color, 50)
        useEffectLevelCardList.push(
            <div className={`relative w-full pt-16  items-center z-0`}>
              {/*<svg width="50" height="50">*/}
              {/*  <circle cx="10" cy="10" r="10" fill={percentageCompleted[i] > 0 ? finalCircleFillColor : '#ADB7B9'}/>*/}
              {/*</svg>*/}
              {/*<button*/}
              {/*    className={`w-44 h-44 bg-white dark:bg-darkBackground`}*/}
              {/*    ref={levelCardRef}*/}
              {/*    onClick={() => {*/}
              {/*      setCurrentCard(i);*/}
              {/*    }}>*/}
              {/*  <div className={`w-full relative h-full rounded-[2rem] p-5 ${*/}
              {/*      i === 0 ? "rounded-br-lg" : `${i === levels.length - 1 ? "rounded-bl-lg" : "rounded-b-lg"}`*/}
              {/*  }`}*/}
              {/*       style={{*/}
              {/*         backgroundColor: percentageCompleted[i] > 0 ? finalCircleFillColor : '#F3F4F6',*/}
              {/*         color: percentageCompleted[i] > 0 ? '#FFFFFF' : 'black',*/}
              {/*       }}*/}
              {/*  >*/}
              {/*    <p className={`font-Sa text-left text-sm mb-1.5`}>Level {i + 1}</p>*/}
              {/*    <p className={`font-SaM text-left text-base`}>{levels[i]}</p>*/}
              {/*    <img src={learningPlanLockedChapter} className={`absolute bottom-6 right-6`}*/}
              {/*         style={{display: percentageCompleted[i] > 0 ? "none" : "block"}}/>*/}
              {/*  </div>*/}
              {/*</button>*/}
                  <ExploreChapterItems key={i} bgColor={isDarkMode ? '#1C2226' : 'white'} ringColor={ringColor}
                                       buttonBgColor={isDarkMode ? '#2E3C41' : '#F3F4F6'}
                                       nav_link={`/mcq/${topic?.[i]?.id}/subtopic_id`}
                                       mainTopic={topic?.[i]?.chapter?.name}
                                       subTopic={topic?.[i]?.topic}
                                       useLocationState={{previousURL: "learningPlan", isMockExam: false}}
                  />
            </div>
        );
      }
      useEffectLevelCardList.push(
          <div disabled={
            // true
              false
          } className={`w-48 h-[17rem] pt-[4rem] z-0 bg-white dark:bg-[#2E3C41] `} onClick={() => {
            navigate(`/mcq/${id}/subtopic_id`, {state: {previousURL: "learningPlan", isMockExam: true}})
          }}>
            <button className={`w-full relative h-full flex flex-col justify-start rounded-[1.5rem] p-6 hover:scale-110 hover:translate-x-[5%] hover:translate-y-[5%] transition-transform`}
                 style={{
                   backgroundColor: '#F3F4F6',
                   color: 'black',
                 }}
            >
              <p className={`font-Sa text-left text-sm mb-1.5`}>{topic?.[0]?.chapter?.name}</p>
              <p className={`font-SaM text-left text-base`}>Revision Worksheet</p>
            </button>
          </div>
      )
      setLevelCardList(useEffectLevelCardList);
    }, []);


  const lineStyle = {
    "--gradient-width": `${((currentCard + 1) / 5) * 80}%`,
  };

  return (
      <div className={`rounded-2xl my-2 relative`}>
          <div
              className={`flex justify-between w-[95%] relative items-center py-9 px-10 bg-contain bg-no-repeat -mb-4 rounded-3xl`}
              style={{
                  backgroundImage: `url(${continuePracticeBg})`,
                  backgroundColor: bgColor,
                  backgroundPositionY: "0rem",
                  backgroundPositionX: "center",
                  backgroundSize: "15rem",
                  zIndex: 10
              }}
          >
              <div className={`flex flex-col`}>
                  <p className={`font-SaB text-white text-xl w-full`} style={{color: textColor}}>{heading}</p>
                  {/* <p className={`font-SaM text-white text-lg mb-1.5 w-full`}>{subheading}</p> */}
              </div>
            {unlocked ? (
                <button className={`font-Sa h-11 text-lg rounded-lg px-5 py-2`} style={{
                  boxShadow: `0px 4px 20px 0px rgba(0, 0, 0, 0.05)`,
                  backgroundColor: buttonBgColor,
                  color: buttonTextColor
                }} onClick={toggleOpen}>
                  {isOpen ? 'Resume' : 'Continue'}
                </button>
            ) : (
                <div className={`flex items-center justify-center w-8 h-8 rounded-full mr-5`} >
                  <img src={lockedIndicator} alt={"lockedIndicator"} className={`w-full`} />
                </div>
            )}
              <div className={`absolute ${unlocked ? 'bottom-[45%]' : 'bottom-[38%]'} left-[55%]`}>
                  <img src={sliderIcon} alt={"yellowSlider"}/>
              </div>
          </div>

          <div
              className={`overflow-hidden z-0 pb-5 flex h-fit bg-[#F9FBFB] rounded-b-3xl relative w-[95%]`}
              style={{
                  maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : "0",
              transition: "max-height 0.5s ease-in-out",
              opacity: isOpen ? "1" : "0",
              visibility: isOpen ? "visible" : "hidden",
              backgroundColor: carouselBgColor
            }}
        >
          <div ref={contentRef} className={`px-5 flex mt-2.5 h-fit pt-2 pb-5 rounded-lg bg-transparent relative w-11/12 z-0`}>
            <CarouselImpl
                visibleSlides={window.innerWidth < 768 ? 1 : 3.5}
                minwidth={"min-content"}
                buttonContainerWidth={"0rem"}
                buttonBgColor={"#F3F4F6"}
                buttonTopPosDivisor={8}
            >
              {levelCardList}
            </CarouselImpl>
          </div>
        </div>
      </div>
  );
}
